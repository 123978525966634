export { matchers } from "/.svelte-kit/generated/client/matchers.js";

export const nodes = [
	() => import("/.svelte-kit/generated/client/nodes/0.js"),
	() => import("/.svelte-kit/generated/client/nodes/1.js"),
	() => import("/.svelte-kit/generated/client/nodes/2.js"),
	() => import("/.svelte-kit/generated/client/nodes/3.js"),
	() => import("/.svelte-kit/generated/client/nodes/4.js"),
	() => import("/.svelte-kit/generated/client/nodes/5.js"),
	() => import("/.svelte-kit/generated/client/nodes/6.js"),
	() => import("/.svelte-kit/generated/client/nodes/7.js"),
	() => import("/.svelte-kit/generated/client/nodes/8.js"),
	() => import("/.svelte-kit/generated/client/nodes/9.js"),
	() => import("/.svelte-kit/generated/client/nodes/10.js"),
	() => import("/.svelte-kit/generated/client/nodes/11.js"),
	() => import("/.svelte-kit/generated/client/nodes/12.js"),
	() => import("/.svelte-kit/generated/client/nodes/13.js"),
	() => import("/.svelte-kit/generated/client/nodes/14.js"),
	() => import("/.svelte-kit/generated/client/nodes/15.js"),
	() => import("/.svelte-kit/generated/client/nodes/16.js")
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/about": [3],
		"/about/church": [4],
		"/about/contact": [5],
		"/about/support": [6],
		"/about/symbol": [7],
		"/blog": [~8],
		"/blog/category": [~10],
		"/blog/category/page/[page]": [~14],
		"/blog/category/[category]": [~11],
		"/blog/category/[category]/page": [~12],
		"/blog/category/[category]/page/[page]": [~13],
		"/blog/page": [~15],
		"/blog/page/[page]": [~16],
		"/blog/[post]": [9]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from "/.svelte-kit/generated/root.svelte";